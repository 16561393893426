body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont !important ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5 !important;
  color:#1D3869 !important;
  padding: 0 !important;
}

.main-container{
  margin: 3% auto;
  width: 100%;
  height: 100%;
  padding: 30px;
  text-align: center;
}

.block{
  margin-bottom: 10px;
  padding: 5px 0px;
}

.text-container{
  margin: 5% auto;
}

.form{
  margin-top: 36px !important;
}

a{
  text-decoration: none;
  color:#1D3869;
}

.MuiListItemIcon-root{
  color: #1D3869 !important;
}

.active{
  .MuiListItemIcon-root{
    color: #d32f2f !important;
  }
} 


// a:hover{
//   text-decoration: underline !important;
//   color: #d32f2f !important;  
// }

.button{
  max-width:250px !important;
  min-width: 230px !important;
}


@media (max-width: 427px) {
  .rdButtons{
    // max-width: 200px;
    display: flex !important;
    flex-direction: column !important;
  }
  .form{
    padding: 0px 10px !important;
  }  
}


.form-check {
  display: flex;
  align-items: center;  
  justify-content: flex-start;
  margin: 1.25em 25%;
  text-align: left;
  @media (max-width: 687px) {
    margin: 1.25em 10%;
  }
  @media (max-width: 425px) {
    margin: 1.25em 0;
  }
}


.form-check-input[type=checkbox] {
  border-radius: .25em;
  margin-right: 15px;
}

form input[type="checkbox"] {
  margin: 0 10px 0 0;
  padding: 5px;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
}

.table-row{
  .inner{
    color:inherit;  
  }
  &--not-active{
    background-color: #dbd9d9af;
    color: #1d386994!important;
  }

}