.title {
  font-size: 36px;
  margin-bottom: 5px;
}
.subtitle{
  font-size: 26px !important;
  font-weight: 700 !important;
}

.image {
  width: 50%;
}


.buttons {
  display: flex;
  justify-content: space-around;

  button {
    margin-right: 0px;
    margin-bottom: 7px;
  }
}


@media (max-width: 768px) {
  .title {
    font-size: 20px !important; 
    margin-bottom: 5px !important;
  }
  .subtitle{
    font-size: 16px !important;
    font-weight: 400 !important;
  }
}
