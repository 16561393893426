.title {
  font-size: 36px;
  margin-bottom: 5px;
}



@media (max-width: 768px) {
  .title {
    font-size: 20px !important; 
    margin-bottom: 5px !important;
  }

}
