.root {
  display: flex;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-right: 10px;
}

.userName {
  font-weight: 500;
  font-size: 14px;
}

.userDetails {
  display: flex;
  flex-direction: column;
}

.additional {
  font-size: 12px;
  opacity: 0.6;
}
