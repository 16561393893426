
  .photoBox {
    position: relative;
    // width: 190px;
    // height: 50%;
    width: 100%;
    height: 160px;
    margin-bottom: 10px;
  }
  
  
  .photoCarlog {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover; 
  }
  
  
  .icon {
    position: absolute;
    z-index: 2;
    color: rgb(252, 252, 252);
    width: 50px !important;
    height: 50px !important;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
  }
  
  .overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .photoBox:hover .overlay {
    opacity: 1;
  }