.container{
  margin: 2rem 0 !important;
  padding: 0 0.8rem !important;
}

.textContainer{
  margin: 1rem 0;
}

.title {
  font-size: 24px !important;
  font-weight: 700 !important;
}
.subtitle{
  font-size: 16px !important;
  margin-bottom: 5px  !important;

}

.docList{

  margin-right: 5px !important;
}


.photoBox {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 200px;
}


.photoCarlog {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover; 
}

.checkbox{
  font-size: 14px !important;
}


.icon {
  position: absolute;
  z-index: 2;
  color: rgb(252, 252, 252);
  width: 50px !important;
  height: 50px !important;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.photoBox:hover .overlay {
  opacity: 1;
}

.comment{
    min-height:40px !important;
    width: 100% !important;
    text-align: left !important;
    padding: 10px 20px !important;
    border-radius: 15px !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}



@media (max-width: 768px) {
  .title {
    font-size: 20px !important; 
  }
  .subtitle{
    font-size: 16px !important;
    font-weight: 400 !important;
  }
}

.infoCard{
  border-radius:15px;
  padding:10px;
  margin:5% auto;
  min-height:200px;
  display:flex;
  flex-direction:column;
  justify-content:space-evenly;
  text-align:left;
}