.container{
  margin: 2rem 0 !important;
  padding: 0 0.8rem !important;
}

.textContainer{
  margin: 1rem 0;
}

.title {
  font-size: 24px !important;
  font-weight: 700 !important;
}
.subtitle{
  font-size: 15px !important;
  font-weight: 600 !important;
}


@media (max-width: 768px) {
  .title {
    font-size: 20px !important; 
  }
  .subtitle{
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

