.root {
  width: 400px;
  padding: 50px;
  border: 1px solid #dedede;
  margin: 50px auto;
}

.field {
  margin-bottom: 20px !important;
  padding: 0px !important;
}


.title {
  text-align: center !important;
  font-weight: bold !important;
  margin: 7px 0px !important;
  text-Transform:uppercase !important;
}

.subtitle{
  font-size: 20px;
}

.form{
  margin-top: 36px !important;
}


@media (max-width: 768px) {
  .title {
    font-size: 20px !important; 
    margin:  5px 0px !important;
  }
  .subtitle{
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .logo {
    width: 69px !important;
  }
  
}