
.subtitle{
  font-size: 16px;
  font-weight: 700 !important;
  margin-bottom: 200px !important;
}

.description{
  font-size: 16px !important;
}

.manyImage {
  // width: 50%;
  max-width: 150px;
  padding-left: 10px;
}

.buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    margin-right: 0px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.block{
  background-color: white;
  border-radius: 15px;
  padding: 10px 0px;
  & --active{
    background-color: none;
  }
}

.bigItem{
  text-align: center;
  line-height: 60px;
  // margin-bottom:5px;
  padding:10px 5px;
  display:flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap
}

.bigItem img{
  height: 110px;
  width: 100%;
  @media (max-width: 424px) {
    height: 90px;
  }
}

@media (max-width: 424px) {
  .image {
    width: 100%;
    // max-width: 85%;
    border-radius: 15px;
  }
  .subtitle{
    font-size: 16px;
    font-weight: 700 !important;
  }
  .buttons{
    .button{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .manyImage {
    // width: 50%;
    max-width: 115px;
    padding-left: 10px;
  }
}
