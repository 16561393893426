.infoCard{
  border-radius:15px;
  padding:10px;
  margin:5% auto;
  min-height:200px;
  display:flex;
  flex-direction:column;
  justify-content:space-evenly;
  text-align:left;
}

.rightBlock{
  // width: 100%;
  // height: 100%;
  padding: 20px;
  text-align: center;
  div{
    width:100%;
  }
}
