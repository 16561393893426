.root{
    max-width: fit-content;
}
.tableRow{
    // cursor: pointer;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.tableCell{
    border-bottom: none !important;
    padding: 6px !important;
    color:#142749 !important;
}
