// .title {
//   // margin:  70px 0 0px 0px !important;
// }
// .subtitle{
//   // margin:  20px 0 15px 0px !important;
// }


@media (max-width: 426px) {
  .title {
    font-size: 16px !important; 
    // margin-bottom: 5px !important;
  }
  .subtitle{
    font-size: 14px !important;
    font-weight: 400 !important;
    // margin-bottom: 10px !important;
  }
}
